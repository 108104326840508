import * as React from "react";
import DefaultLayout from "../components/DefaultLayout";

// markup
const Newsletter = () => {
  return (
    <DefaultLayout>
      <title>Newsletter</title>
    </DefaultLayout>
  );
};

export default Newsletter;
